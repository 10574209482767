import { Model } from "../lib";
import { action, observable, computed } from "mobx";

export default class User extends Model {
  urlRoot = "/users";

  constructor(attributes, store) {
    let defaultAttributes = {
      invite_code: null,
      account_id: null,
      last_name: null,
      avatar_url: null,
      password: null,
      mercurio: null,
      name: null,
      email: null,
      role: null,
      type: null,
      password_updated_at: null,
    };

    super(attributes, store, defaultAttributes);
  }

  @observable company = null;

  abilities = {
    createReturn: () => {
      return this.isAdmin() || this.company.active;
    },
    createExchange: () => {
      return this.isAdmin() || this.company.active;
    },
  };

  @action
  afterSetData() {
    if (this.account && !this.company) {
      this.company = this._store.appStore.accounts.getFromStoredData(
        this.account
      );
    }
  }

  @computed
  get webURI() {
    return `${this._store.baseWebURI}/${this.id}`;
  }

  @computed
  get fullName() {
    if (!this.last_name) return this.name;

    return `${this.name} ${this.last_name}`;
  }

  @computed
  get currentPlan() {
    return `${this.company.current_plan
      .charAt(0)
      .toUpperCase()}${this.company.current_plan.slice(1).toLowerCase()}`;
  }

  // TODO: modelizar esta configuración en el backend y quitar el hardcodeo de los ID.
  @computed
  get hasSerializableVariants() {
    return [1, 4372].includes(this.account.id);
  }

  isMultiAccount() {
    if (!this.company) {
      return;
    }

    return this.company.isWarehouseOwner();
  }

  preferCrossdocking() {
    if (!this.company) {
      return;
    }

    return this.company.picking_preference_own === "cross_docking";
  }

  isAdmin() {
    return this.account.id === 1;
  }

  isEmailConfirmed() {
    return !!this.confirmed_at;
  }

  isManager() {
    return this.role === "manager";
  }

  isRegular() {
    return this.role === "regular";
  }

  paymentModality() {
    return this.account.payment_modality;
  }

  isPrePaid() {
    return this.account.payment_modality === "prepaid";
  }

  isPostPaid() {
    return this.account.payment_modality === "postpaid";
  }

  isWhsOwner() {
    if (!this.account.id) return false;

    return this.account.stats.warehouses.with_warehouse_system > 0;
  }

  canEditDispatchDate() {
    return this.company.isWarehouseOwner() || this.isAdmin();
  }

  create(company_token) {
    return this._store.appStore.coreAPIClient.sendRequest(
      this.urlRoot,
      "POST",
      this.toJS(),
      false,
      { Authorization: `Bearer ${company_token}` }
    );
  }

  invite(code) {
    this.invite_code = code;
    return this._store.appStore.coreAPIClient.sendRequest(
      `${this.urlRoot}?fields=account.onboarding`,
      "POST",
      this.toJS(),
      false
    );
  }

  async saveNewPassword(params, credentials) {
    try {
      const json = await this._store.appStore.users.saveNewPassword(
        params,
        credentials
      );

      this.beginUpdate();
      this.set(json.results);
      this.endUpdate();
    } catch (e) {
      return e;
    }
  }
}
