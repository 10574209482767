import { Store } from "../lib";
import { ShippingOption } from "../models";

export default class ShippingOptionsStore extends Store {
  model = ShippingOption;

  fetchOptions(params) {
    let fetchParams = Object.assign(
      {
        weight: 100,
        categories: "economic,express,super_express",
      },
      params
    );

    return this.fetch("", fetchParams);
  }
  async fetchOptionsWithId(params) {
    let fetchParams = Object.assign(
      {
        weight: 100,
        categories: "economic,express,super_express",
      },
      params
    );
    const searchString = new URLSearchParams(fetchParams);
    return this.appStore.coreAPIClient
      .sendRequest(`/shipping_options/?${searchString}`, "GET")
      .then((options) => {
        return options.results.map((obj) => ({
          ...obj,
          id: Math.random().toString(36).substring(2),
        }));
      });
  }
  calculateShipmentPrice(params) {
    return this.fetch("default", params, {}, false, null, false);
  }
}
