import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import Map from "../../Map/Map";

import { Flex, Panel, TextV2 } from "shipnow-mercurio";

const AddressMap = observer((props) => {
  const { className, draggable, height, onDrag, position, width } = props;

  if (position && position.every((c) => !!c)) {
    return (
      <Map
        className={className}
        draggable={draggable}
        height={height}
        onDrag={onDrag}
        position={position}
        width={width}
      />
    );
  } else {
    return (
      <Panel
        className={className}
        color="blackSurface"
        style={{ height: height }}
      >
        <Flex style={{ height: "100%" }}>
          <TextV2 color="blackMediumEmphasis">
            No se pudo encontrar la dirección en el mapa
          </TextV2>
        </Flex>
      </Panel>
    );
  }
});

AddressMap.propTypes = {
  className: PropTypes.string,
  draggable: PropTypes.bool,
  height: PropTypes.string,
  onDrag: PropTypes.func,
  position: PropTypes.array,
  width: PropTypes.string,
};

AddressMap.defaultProps = {
  className: null,
  draggable: false,
  height: "400px",
  onDrag: null,
  position: null,
  width: "100%",
};

export default AddressMap;
