import { Store } from "../lib";
import { Variant } from "../models";

export default class VariantsStore extends Store {
  model = Variant;
  baseWebURI = "/app/inventory/variants";

  searchStock(id, params) {
    let fetchParams = Object.assign({}, params);

    return this.fetch(`${id}/stocks`, fetchParams);
  }

  searchImages(id, params) {
    let fetchParams = Object.assign({}, params);

    return this.fetch(`${id}/images`, fetchParams);
  }

  export(variant_ids, company) {
    return this.appStore.coreAPIClient.post("/variants/export", {
      in_list: variant_ids,
    });
  }

  fetchVariantByExternalRef(reference) {
    return this.appStore.coreAPIClient.search(
      `${this.urlRoot}/?reference=${reference}&type=variants`
    );
  }

  update(id, params) {
    return this.appStore.coreAPIClient.sendRequest(
      `/variants/${id}`,
      "PUT",
      params
    );
  }

  getImages(id) {
    return this.appStore.coreAPIClient.sendRequest(
      `/variants/${id}/images`,
      "GET"
    );
  }

  archive(variant) {
    const params = {
      id: variant.id,
      archived: !variant.archived,
    };

    return this.appStore.coreAPIClient.put(`/variants`, params).then((res) => {
      if (res && res["results"]) {
        variant.set(res["results"]);
        variant.endUpdate();
      }
    });
  }
}
