import { Store } from "../lib";
import { User } from "../models";

export default class UsersStore extends Store {
  model = User;
  baseWebURI = "/app/users";

  confirmEmail(confirmation_token) {
    return this.appStore.coreAPIClient.sendRequest(
      `/users/confirmation`,
      "POST",
      { confirmation_token: confirmation_token },
      false
    );
  }

  validateConfirmationMail() {
    return this.appStore.coreAPIClient.sendRequest(`/users/me`, "GET");
  }

  resendConfirmationEmail(expired_token) {
    if (expired_token) {
      return this.appStore.coreAPIClient.sendRequest(
        `/users/resend_confirmation`,
        "POST",
        { confirmation_token: expired_token },
        false
      );
    } else {
      return this.appStore.coreAPIClient.sendRequest(
        `/users/resend_confirmation`,
        "POST"
      );
    }
  }

  saveNewPassword(params, credentials) {
    return this.appStore.coreAPIClient.sendRequest(
      `${this.urlRoot}/${this.id}/password`,
      "PUT",
      params,
      false,
      {
        Authorization: `Basic ${credentials}`,
      }
    );
  }
}
