import React from "react";
import PropTypes from "prop-types";

import useMediaQuery from "../../../hooks/useMediaQuery";

import ShipnowLogo from "../../Session/Components/ShipnowLogo";

import { Button, TextV2 } from "shipnow-mercurio";

import styles from "./MessageLayout.styles";
import { withStyles } from "react-jss";

import { ReactComponent as TransfersImg } from "../../../assets/images/mercurio_empty_state_recolecciones.svg";
import { ReactComponent as ConsumptionImg } from "../../../assets/images/mercurio_empty_state_movimientos.svg";
import { ReactComponent as StoresImg } from "../../../assets/images/mercurio_empty_state_integraciones.svg";
import { ReactComponent as WarehousesImg } from "../../../assets/images/mercurio_empty_state_deposito.svg";
import { ReactComponent as RemissionImg } from "../../../assets/images/mercurio_empty_state_remision.svg";
import { ReactComponent as VariantsImg } from "../../../assets/images/mercurio_empty_state_variantes.svg";
import { ReactComponent as ProductsImg } from "../../../assets/images/mercurio_empty_state_productos.svg";
import { ReactComponent as OrdersImg } from "../../../assets/images/mercurio_empty_state_pedidos.svg";
import { ReactComponent as Illustrationn } from "../../../assets/images/mercurio_error_vacio.svg";
import { ReactComponent as KitsImg } from "../../../assets/images/mercurio_empty_state_kits.svg";
import { ReactComponent as TrackingImg } from "../../../assets/images/mercurio_error_404.svg";
import { ReactComponent as InvoicesImg } from "../../../assets/images/mercurio_empty_state_facturas.svg";

const MessageLayout = (props) => {
  const {
    classes,
    btnText,
    message,
    onClick,
    title,
    showLogo,
    disabled,
  } = props;

  const mobile = useMediaQuery("(max-width: 769px)");

  const renderLocalImage = () => {
    const { image } = props;

    switch (image) {
      case "no-content":
        return <Illustrationn style={{ width: "70%" }} />;
      case "consumption":
        return <ConsumptionImg className={`${classes.imgStyles} mt-3`} />;
      case "invoices":
        return <InvoicesImg className={`${classes.imgStyles} mt-3`} />;
      case "kits":
        return <KitsImg className={classes.imgStyles} />;
      case "orders":
        return <OrdersImg className={classes.imgStyles} />;
      case "products":
        return <ProductsImg className={classes.imgStyles} />;
      case "remission":
        return <RemissionImg className={classes.imgStyles} />;
      case "stores":
        return <StoresImg className={classes.imgStyles} />;
      case "tracking":
        return (
          <TrackingImg
            style={{ height: "60%", maxHeight: "320px", width: "60%" }}
          />
        );
      case "transfers":
        return <TransfersImg className={classes.imgStyles} />;
      case "variants":
        return <VariantsImg className={classes.imgStyles} />;
      case "warehouses":
        return <WarehousesImg className={classes.imgStyles} />;
      default:
        return null;
    }
  };

  const renderImage = () => {
    let component;
    // TODO: Dejar de recibir string, pasar todas las imagenes por prop.
    if (typeof props.image === "string") {
      component = renderLocalImage();
    } else {
      component = props.image;
    }

    return component;
  };

  return (
    <>
      {showLogo && <ShipnowLogo />}

      <div className={classes.wrapper}>
        {renderImage()}

        {title && (
          <div className={classes.textBox}>
            <TextV2
              align="center"
              margin="t-24"
              size={mobile ? "subtitle" : "title"}
            >
              {title}
            </TextV2>
          </div>
        )}

        {message && (
          <div className={classes.textBox}>
            <TextV2 align="center" margin={title ? "t-16" : "t-24"}>
              {message}
            </TextV2>
          </div>
        )}

        {btnText && (
          <Button disabled={disabled} margin="y-24" onClick={onClick}>
            {btnText}
          </Button>
        )}
      </div>
    </>
  );
};

MessageLayout.propTypes = {
  btnText: PropTypes.string,
  disabled: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  mt: PropTypes.string,
  onClick: PropTypes.func,
  showLogo: PropTypes.bool,
  title: PropTypes.string,
};

MessageLayout.defaultProps = {
  btnText: null,
  disabled: false,
  image: "no-content",
  message: null,
  mt: "0px",
  onClick: null,
  showLogo: false,
  title: "Nada por aquí...",
};

export default withStyles(styles)(MessageLayout);
