import Variant from "./Variant";
import { action, computed, observable } from "mobx";

export default class Kit extends Variant {
  @observable variants = null;
  @observable publications = null;
  @observable stockByWarehouse = null;
  @observable stockByShowroom = null;

  constructor(attributes, store) {
    let defaultAttributes = {
      title: null,
      external_reference: null,
      external_reference_user: null,
      fiscal_type_number: null,
      currency: null,
      seller_id: null,
      account: null,
      images: null,
      min_stock_own: null,
      related_variants: [],
      kit: true,
      picking_preference_own: null,
      declared_dimensions: {
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
      },
      dimensions: {},
      price: {
        buy: null,
        retail: null,
        wholesale: null,
      },
      properties: {},
    };
    const attrs = Object.assign({}, defaultAttributes, attributes);
    super(attrs, store);
  }

  abilities = {
    kind_toogled: (loggedInUser) => true,
  };

  @computed
  get webURI() {
    return `${this._store.baseWebURI}/${this.id}`;
  }

  @action
  fetchVariants() {
    if (this.variants) {
      return this;
    } else {
      this.beginUpdate();

      const variantsIds = this.related_variants.map((v) => v.variant_id);

      this._store.appStore.variants
        .search({ in_list: variantsIds })
        .andThen((res) => {
          this.variants = res.toArray();

          this.endUpdate();
        });
    }
    return this;
  }

  @action
  fetchPublications() {
    if (this.publications) {
      return this;
    } else {
      this.beginUpdate();
      this._store.appStore.publications
        .search({ product_id: this.id })
        .andThen((res) => {
          this.publications = res.toArray();

          this.endUpdate();
        });
    }
    return this;
  }

  getNewVariant() {
    let variant = this._store.appStore.variants.getNew();
    let uniqueID = "_" + Math.random().toString(36).substr(2, 9);

    variant.title = `${this.title}-${uniqueID}`;
    variant.seller_id = this.seller_id;

    return variant;
  }

  get isProduct() {
    return false;
  }
  get isKit() {
    return true;
  }
  get isVariant() {
    return false;
  }

  @computed
  get isSelected() {
    return this._store.appStore.ui.lists.selectedKits.indexOf(this) !== -1;
  }

  updateValues(values) {
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        this[key] = values[key];
      }
    }
  }
}
