import React, { useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import {
  Button,
  Dropzone,
  ImageItemList,
  ImageItem,
  Box,
} from "shipnow-mercurio";
import { useResponseToast } from "../../../hooks/useResponseToast";

const moment = require("moment");

const ImagesEditor = observer((props) => {
  const [images, setImages] = useState(props.images || []);
  const [skeletonsImage, setSkeletonsImage] = useState(0);
  const { errorToast } = useResponseToast();

  const handleDeleteImage = (image) => {
    const newImages = images.filter(
      (i) => i.original_url !== image.original_url
    );
    setImages(newImages);
    props.onChange(null, newImages, "images");
  };

  const handleImageAdd = (images) => {
    pushSkeletonsImages(images.length);
    cloudinaryUpload(images);
  };

  const cloudinaryUpload = (files) => {
    const url = "https://api.cloudinary.com/v1_1/cloud9/image/upload";

    for (let file of files) {
      var data = new FormData();
      data.append("upload_preset", "xphxxaqm");
      data.append("api_key", "675852613763464");
      data.append("file", file);
      data.append("timestamp", moment());

      const config = {
        method: "POST",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        body: data,
      };

      fetch(url, config).then((res, err) => {
        if (res.ok) {
          res
            .json()
            .then((r) => {
              const imageUploaded = {
                original_url: r.url,
              };

              handleNewImage(imageUploaded);
            })
            .catch((err) => {
              errorToast(
                <div>
                  Ups! Parece que hubo un problema al subir la imagen{" "}
                  <p style={{ display: "none" }}>{err?.toString()}</p>
                </div>
              );
            });
        } else {
          errorToast(
            <div>
              Ups! Parece que hubo un problema al subir la imagen{" "}
              <p style={{ display: "none" }}>{err.toString()}</p>
            </div>
          );
        }
      });
    }
  };

  const handleNewImage = (image) => {
    setImages((prevState) => [...prevState, image]);
    props.onChange(null, images, "images");
  };

  const pushSkeletonsImages = (skeletonsCount) => {
    setSkeletonsImage((prevValue) => prevValue + skeletonsCount);
  };

  const renderSkeletonsImage = () => {
    let skeletons = [];
    for (var i = 0; i < skeletonsImage; i++) {
      skeletons.push(
        <ImageItem key={`${i}-loading-${moment()}`} loading size="md" />
      );
    }
  };

  return (
    <Box margin="t-24">
      <ImageItemList size="md">
        {renderSkeletonsImage()}

        {images.map((i) => (
          <ImageItem
            key={`${moment()}-${i.original_url}-${i.id}`}
            size="md"
            image={i.original_url || i.image_url}
          >
            <Button
              icon="delete"
              onClick={() => handleDeleteImage(i)}
              type="icon"
            />
          </ImageItem>
        ))}
      </ImageItemList>

      <Dropzone onFileAdd={handleImageAdd} />
    </Box>
  );
});

ImagesEditor.propTypes = {
  images: PropTypes.array,
  onChange: PropTypes.func,
};

ImagesEditor.defaultProps = {
  Images: null,
  onChange: null,
};

export default withStore(ImagesEditor);
