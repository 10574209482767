import { action, observable, computed } from "mobx";

import {
  RESTClient,
  CookieClient,
  LocalStorageClient,
  LocalStorageStoreClient,
} from "../lib";

import {
  AccountsStore,
  OrdersStore,
  PoSsStore,
  ShippingServicesStore,
  CarriersStore,
  CountriesStore,
  StoreTypesStore,
  UIStore,
  UsersStore,
  WarehousesStore,
  PickupOptionsStore,
  GoodsInOptionsStore,
  ShipmentsStore,
  ActivityStore,
  ShippingOptionsStore,
  ProductsStore,
  ReturnsGroupsStore,
  GeocoderStore,
  PostOfficesStore,
  StocksStore,
  VariantsStore,
  PublicationsStore,
  KitsStore,
  GoodsInsStore,
  GoodsInItemsStore,
  GoodsInRecurrencesStore,
  DispatchesStore,
  ShipmentItemsStore,
  ChargesStore,
  ChargeTypesStore,
  TrackerStore,
  TariffsStore,
  InvoicesStore,
  MovementsStore,
  InvitesStore,
  CheckInsStore,
  ContactsStore,
} from ".";

export default class AppStore {
  @observable isLoading = true;
  @observable loggedInUser = null;
  @observable salesforce_token = null;
  @observable cookieEmailModal = null;

  salesforce_org_id = process.env.REACT_APP_SALESFORCE_ID || "00D5w000003HVJQ";
  app_url = process.env.REACT_APP_URL || "http://localhost:3000";
  sn_cuit = process.env.REACT_APP_SHIPNOW_CUIT || "30714736724";
  afip_url =
    process.env.REACT_APP_AFIP_URL || "https://www.afip.gob.ar/fe/qr/?p=";

  settings = {
    refreshRate: 5, // in minutes
  };

  constructor() {
    this.salesforceToken = new LocalStorageClient("sn_salesforce");
    this.storedToken = new CookieClient("sn_loggin_user_token");
    this.storedUser = new CookieClient("sn_loggin_user_id");

    this.salesforce_token = this.salesforceToken.get("token");

    // create adapters
    this.coreAPIClient = new RESTClient(
      process.env.REACT_APP_SHIPNOW_CORE_URL,
      this.storedToken.get()
    );
    this.thothAPIClient = new RESTClient(
      process.env.REACT_APP_SHIPNOW_THOTH_URL,
      this.storedToken.get()
    );
    this.geoCoderAPIClient = new RESTClient(
      process.env.REACT_APP_SHIPNOW_GEOCODER_URL,
      this.storedToken.get()
    );
    this.storesAPIClient = new RESTClient(
      process.env.REACT_APP_SHIPNOW_STORES_URL,
      this.storedToken.get()
    );
    this.billingAPIClient = new RESTClient(
      process.env.REACT_APP_SHIPNOW_BILLING_URL,
      this.storedToken.get()
    );

    this.localStorageClient = new LocalStorageStoreClient("sn");

    // initialize stores
    this.stores = new Map();

    // Domain stores
    this.stores.set(
      "shippingServices",
      new ShippingServicesStore(this.coreAPIClient, this)
    );
    this.stores.set(
      "storeTypes",
      new StoreTypesStore(this.coreAPIClient, this)
    );
    this.stores.set("carriers", new CarriersStore(this.coreAPIClient, this));
    this.stores.set("countries", new CountriesStore(this.coreAPIClient, this));
    this.stores.set("orders", new OrdersStore(this.coreAPIClient, this));
    this.stores.set("accounts", new AccountsStore(this.coreAPIClient, this));
    this.stores.set("users", new UsersStore(this.coreAPIClient, this));
    this.stores.set("pointsOfSale", new PoSsStore(this.coreAPIClient, this));
    this.stores.set(
      "warehouses",
      new WarehousesStore(this.coreAPIClient, this)
    );
    this.stores.set(
      "pickupOptions",
      new PickupOptionsStore(this.coreAPIClient, this)
    );
    this.stores.set(
      "goodsInOptions",
      new GoodsInOptionsStore(this.coreAPIClient, this)
    );
    this.stores.set("shipments", new ShipmentsStore(this.coreAPIClient, this));
    this.stores.set("products", new ProductsStore(this.coreAPIClient, this));
    this.stores.set(
      "returnsGroup",
      new ReturnsGroupsStore(this.coreAPIClient, this)
    );
    this.stores.set(
      "shippingOptions",
      new ShippingOptionsStore(this.coreAPIClient, this)
    );
    this.stores.set(
      "postOffices",
      new PostOfficesStore(this.coreAPIClient, this)
    );
    this.stores.set("stocks", new StocksStore(this.coreAPIClient, this));
    this.stores.set("variants", new VariantsStore(this.coreAPIClient, this));
    this.stores.set(
      "publications",
      new PublicationsStore(this.coreAPIClient, this)
    );
    this.stores.set("kits", new KitsStore(this.coreAPIClient, this));
    this.stores.set("goodsIns", new GoodsInsStore(this.coreAPIClient, this));
    this.stores.set(
      "goodsInItems",
      new GoodsInItemsStore(this.coreAPIClient, this)
    );
    this.stores.set(
      "goodsInRecurrences",
      new GoodsInRecurrencesStore(this.coreAPIClient, this)
    );
    this.stores.set(
      "dispatches",
      new DispatchesStore(this.coreAPIClient, this)
    );
    this.stores.set(
      "shipmentItems",
      new ShipmentItemsStore(this.coreAPIClient, this)
    );
    this.stores.set("charges", new ChargesStore(this.coreAPIClient, this));
    this.stores.set(
      "chargeTypes",
      new ChargeTypesStore(this.coreAPIClient, this)
    );
    this.stores.set("tariffs", new TariffsStore(this.coreAPIClient, this));
    this.stores.set("tracker", new TrackerStore(this.coreAPIClient, this));
    this.stores.set("invoices", new InvoicesStore(this.coreAPIClient, this));
    this.stores.set(
      "movements",
      new MovementsStore(this.billingAPIClient, this)
    );
    this.stores.set("invites", new InvitesStore(this.coreAPIClient, this));
    this.stores.set("checkIns", new CheckInsStore(this.coreAPIClient, this));
    this.stores.set("contacts", new ContactsStore(this.coreAPIClient, this));

    // Activity Events Store
    this.stores.set("activities", new ActivityStore(this.thothAPIClient, this));

    // UI stores
    this.stores.set("ui", new UIStore(this.localStorageClient, this));
    this.stores.set(
      "geocoder",
      new GeocoderStore(this.localStorageClient, this)
    );

    // create easy stores getters
    this.stores.forEach((store, key) => {
      Object.defineProperty(this, key, {
        get: (v) => store,
      });

      store.updateThreshold = this.settings.refreshRate;
    });

    // is already a session open?
    if (this.storedToken.get() && this.storedUser.get()) {
      this.users
        .get(this.storedUser.get(), true, null, {
          fields: "account.onboarding",
        })
        .andThen((res, err) => {
          if (err) {
            // something went terrible wrong....
            this.signOut();
            this.isLoading = false;
          } else {
            this.setCurrentUser(res);
            this.ui.afterSignIn();
            this.isLoading = false;
          }
        });
    } else {
      this.isLoading = false;
    }
  }

  @action
  signOut() {
    this.coreAPIClient.sendRequest("/users/logout", "POST");

    this.storedToken.remove();
    this.storedUser.remove();
    this.salesforceToken.delete("token");

    this.coreAPIClient.token = null;
    this.thothAPIClient.token = null;
    this.geoCoderAPIClient.token = null;
    this.storesAPIClient.token = null;
    this.billingAPIClient.token = null;

    this.loggedInUser = null;
    this.salesforce_token = null;

    this.stores.forEach((store, key) => {
      store.clear();
    });
  }

  @action
  signIn(user, password) {
    return this.authenticate(user, password).then((res) => {
      return this.saveInfo(res);
    });
  }

  @action
  authenticate(user, password) {
    return this.coreAPIClient.authenticate(
      user,
      password,
      "/users/authentication/v2"
    );
  }

  @action
  saveInfo(data) {
    this.setCurrentUser(this.users.store(data));

    this.ui.afterSignIn();

    this.salesforce_token = this.loggedInUser.salesforce_token;

    // save the info in storage
    this.storedToken.set(this.coreAPIClient.token);
    this.storedUser.set(this.loggedInUser.id);
    this.salesforceToken.post("token", this.salesforce_token);

    //authenticate others shipnow Clients
    this.thothAPIClient.token = this.coreAPIClient.token;
    this.geoCoderAPIClient.token = this.coreAPIClient.token;
    this.storesAPIClient.token = this.coreAPIClient.token;
    this.billingAPIClient.token = this.coreAPIClient.token;

    return this;
  }

  @action
  recoverPassword(email) {
    return this.coreAPIClient.password_recovery(email);
  }

  @action resetPassword(token, email) {
    return this.coreAPIClient.update_password(token, email);
  }

  @action checkToken(token) {
    return this.coreAPIClient.check_password_recovery(token);
  }

  @action
  setCurrentUser(user) {
    this.loggedInUser = user;
    this.cookieEmailModal = new CookieClient(
      `sn_email_modal_check_${this.loggedInUser.company.id}`
    );
  }

  @computed
  get isLoggedIn() {
    const loggedInUser = this.loggedInUser; // I need to do this for MOBX to catch it....
    return this.coreAPIClient.token != null && loggedInUser != null;
  }

  @computed
  get isLoggingIn() {
    const loggedInUser = this.loggedInUser; // I need to do this for MOBX to catch it....
    return this.coreAPIClient.token != null && loggedInUser == null;
  }

  @computed
  get loggedInUserKey() {
    if (this.isLoggedIn) {
      return this.loggedInUser.id;
    }

    return null;
  }

  getCurrentTheme() {
    return "shipnow";
  }
}
