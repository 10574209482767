import { Model } from "../lib";
import { computed, action, observable } from "mobx";

import startCase from "lodash/startCase";
import moment from "moment-timezone";
import Account from "./Account";

export default class GoodsIn extends Model {
  constructor(attributes, store) {
    let defaultAttributes = {
      from_warehouse_id: null,
      to_warehouse_id: null,
      seller_id: null,
      type: null,
      goods_in_recurrence_id: null,
      requested_from: null,
      requested_to: null,
      requested_at: null,
      confirmed_from: null,
      confirmed_to: null,
      confirmed_at: null,
      cancelled_at: null,
      received_at: null,
      to_process_at: null,
      status: null,
      stats: {
        items: null,
      },
      resource_type: "",
      package_type: "package",
      packages_count: 0,
      total_volume: null,
      driver: null,
      shipping_category: null,
    };

    super(attributes, store, defaultAttributes);
  }

  urlRoot = "/goods_ins";
  logs = null;
  REGEX_VEHICLE_LICENSE_PLATE = /^\w{3}\d{3}$|\w{2}\d{3}\w{2}$|\d{3}\w{3}$|\w{1}\d{3}\w{3}$/;
  REGEX_INSURANCE_NUMBER = /^\d+((-\d+)*|(\/\d+)*)*$/;

  REGEX_DNI = /^\d{8}$/;

  @observable orders = [];
  @observable returningOrders = [];
  @observable variants = [];
  @observable recurrence;

  to_whs;
  from_whs;

  abilities = {
    edited: (loggedInUser) => this.isRequested(),
    requestedFromEdited: (loggedInUser) => this.isRequested(),
    driverEdited: (loggedInUser) => this.isTransfer() && this.isRequested(),
    itemsEdited: (loggedInUser) => this.isRequested() || this.isConfirmed(),
    confirmed: (loggedInUser) =>
      !this.isOpen() &&
      this.isRequested() &&
      this.isWarehouseOwner(loggedInUser),
    received: (loggedInUser) =>
      this.isTransfer() &&
      this.isConfirmed() &&
      this.isWarehouseOwner(loggedInUser),
    cancelled: (loggedInUser) => {
      if (this.isWarehouseOwner(loggedInUser)) {
        return !this.isCancelled() && !this.isCompleted();
      } else {
        return this.isRequested();
      }
    },
  };

  @action
  afterSetData() {
    if (!this.to_whs && this.to_warehouse && this._store) {
      if (!this.to_warehouse["address"]) {
        this.to_warehouse["address"] = this.to_address;
      }

      this.to_whs = this._store.appStore.warehouses.getFromData(
        this.to_warehouse
      );
    }

    if (!this.from_whs && this.from_warehouse && this._store) {
      if (!this.from_warehouse["address"]) {
        this.from_warehouse["address"] = this.from_address;
      }

      this.from_whs = this._store.appStore.warehouses.getFromData(
        this.from_warehouse
      );
    }

    if (this.to_warehouse) {
      this.to_warehouse_id = this.to_warehouse.id;
    }

    if (this.from_warehouse) {
      this.from_warehouse_id = this.from_warehouse.id;
    }
  }

  isPickup() {
    return this.type === "Pickup";
  }

  isTransfer() {
    return this.type === "Transfer";
  }

  isTypeOrder() {
    return this.resource_type === "Order";
  }

  isTypeVariant() {
    return this.resource_type === "Variant";
  }

  isTypePackage() {
    return this.package_type === "package";
  }

  isTypePallet() {
    return this.package_type === "pallet";
  }

  isClosed() {
    return ["received", "processing", "completed", "cancelled"].includes(
      this.status
    );
  }

  isOpen() {
    return !this.requested_from && !this.requested_to;
  }

  wasConfirmed() {
    return !(!this.confirmed_from && !this.confirmed_to);
  }

  wasRequested() {
    return !this.confirmed_from && !this.confirmed_to;
  }
  // en orden :
  isRequested() {
    return this.status === "requested"; //a confirmar
  }

  isConfirmed() {
    return this.status === "confirmed"; //a recolectar
  }

  isReceived() {
    return this.status === "received"; //a procesar
  }

  isProcessing() {
    return this.status === "processing"; //procesando
  }

  isCompleted() {
    return this.status === "completed"; // completado
  }

  isCancelled() {
    return this.status === "cancelled"; //cancelado
  }

  isSameDay() {
    return this.shipping_category === "super_express";
  }

  isFinalStatus() {
    return ["cancelled", "completed"].includes(this.status);
  }

  hasVariants() {
    return this.isTypeVariant() && this.pickupCount() > 0;
  }
  pickupCount() {
    return this.stats.items && this.stats.items.pickup_count;
  }
  pickupSent() {
    return this.stats.items && this.stats.items.pickup_sent;
  }
  pickupReceived() {
    return this.stats.items && this.stats.items.pickup_received;
  }
  pickupLabeled() {
    return this.stats.items && this.stats.items.pickup_labeled;
  }
  deliveryCount() {
    return this.stats.items && this.stats.items.delivery_count;
  }
  deliverySent() {
    return this.stats.items && this.stats.items.delivery_sent;
  }
  deliveryReceived() {
    return this.stats.items && this.stats.items.delivery_received;
  }
  pendingLabels() {
    return this.pickupCount() - this.pickupLabeled();
  }

  isWarehouseOwner(user) {
    return (
      user.isAdmin() ||
      (this.to_warehouse &&
        user.account.id === this.to_warehouse.owner_account.id)
    );
  }

  toShipnow() {
    return this.to_warehouse && this.to_warehouse.owner_account.id === 1;
  }

  cancelledByDebt() {
    return (
      this.status_reason && this.status_reason === "inactive_account_by_debt"
    );
  }

  cancelledByWaitingOnboarding() {
    return (
      this.status_reason &&
      this.status_reason === "inactive_account_by_waiting_for_onboarding"
    );
  }
  cancelledInactiveByOthers() {
    return (
      this.status_reason && this.status_reason === "inactive_account_by_others"
    );
  }

  cancelByInactivity() {
    return (
      this.status_reason &&
      this.status_reason === "inactive_account_by_inactivity"
    );
  }
  hasDoublePrice() {
    const date = this.confirmed_from || this.requested_from;
    const requestedAt = this.requested_at;

    return (
      moment(date).isSame(requestedAt, "day") &&
      moment(date).isSame(requestedAt, "month") &&
      moment(date).isSame(requestedAt, "year")
    );
  }
  @computed
  get modelAccount() {
    return this.account && new Account(this.account);
  }
  @computed
  get initialStatus() {
    const loggedInUser = this._store.appStore.loggedInUser;

    if (
      this.requested_from &&
      this.requested_from.isSame(moment(), "day") &&
      loggedInUser.isWhsOwner()
    ) {
      return "confirmed";
    }

    return "requested";
  }

  @computed
  get webURI() {
    return `${this._store.baseWebURI}/${this.id}`;
  }

  @computed
  get externalReference() {
    return this.external_reference ? this.external_reference : this.id;
  }

  @computed
  get isRecurrent() {
    return this.recurrence ? true : false;
  }

  @computed
  get goodsInSummary() {
    const type = this.isPickup() ? "Recolección" : "Ingreso";
    let content = "";

    if (this.isTypeOrder()) {
      content = "pedidos por";
    } else {
      content = "stock en";
    }

    return `${type} de ${content}`;
  }

  @computed
  get fromHour() {
    return this.status === "requested"
      ? this.requested_from
      : this.confirmed_from;
  }

  @computed
  get toHour() {
    return this.status === "requested" ? this.requested_to : this.confirmed_to;
  }

  @computed
  get statusChangedAt() {
    const timestamps = {
      created: this.created_at,
      requested: this.created_at,
      confirmed: this.confirmed_at,
      received: this.received_at,
      processing: this.processing_at,
      completed: this.completed_at,
      cancelled: this.cancelled_at,
    };

    return timestamps[this.status];
  }

  @computed
  get icon() {
    return this.isPickup() ? "itemAdd" : "warehouse";
  }

  @computed
  get color() {
    if (this.isTypeOrder()) {
      return "greyLight";
    } else {
      return "highlightLight";
    }
  }

  @computed
  get statusIcon() {
    const icons = {
      requested: { icon: "created" },
      confirmed: { icon: "delivered" },
      received: { icon: "spinner" },
      processing: { icon: "spinner" },
      completed: { icon: "delivered" },
      cancelled: { icon: "cancelled" },
    };

    return icons[this.status];
  }

  @computed
  get statusColor() {
    const colors = {
      requested: "warning",
      confirmed: "grey",
      received: "grey",
      processing: "highlight",
      completed: "success",
      cancelled: "error",
    };

    return colors[this.status];
  }

  @computed
  get statusText() {
    const statuses = {
      requested: "A confirmar",
      confirmed: this.isPickup() ? "A recolectar" : "A ingresar",
      received: "A procesar",
      processing: "Procesando",
      completed: "Completado",
      cancelled: "Cancelado",
    };

    if (this.isOpen() && this.isRequested()) return "Abierto";
    return statuses[this.status];
  }

  @computed
  get dateStatus() {
    const statuses = {
      requested: "Solicitado para el",
      confirmed: "Agendado para el",
      received: "Recibido el",
      processing: this.isPickup() ? "Recolectado" : "Procesando el",
      completed: "Completado el",
      cancelled: "Cancelado el",
    };
    if (this.isOpen() && this.isRequested()) return "Creado el";
    return statuses[this.status];
  }

  @computed
  get statusParse() {
    const statuses = {
      requested: "Solicitado",
      confirmed: "Agendado",
      received: "Recibido",
      processing: this.isPickup() ? "Recolectado" : "Ingresado",
      completed: "Completado",
      cancelled: "Cancelado",
    };
    return statuses[this.status];
  }

  @computed
  get scheduled() {
    let scheduledFrom = this.confirmed_from || this.requested_from;
    let scheduledTo = this.confirmed_to || this.requested_to;

    return { from: scheduledFrom, to: scheduledTo };
  }

  @computed
  get packageCount() {
    if (this.isTypeOrder()) {
      return this.orders.reduce(
        (acumulator, order) => acumulator + order.packageCount,
        0
      );
    }
    return this.packages_count;
  }

  @computed
  get reference() {
    return this.external_reference || this.id;
  }

  @computed
  get toWarehouseAddress() {
    if (!this.to_address) {
      return "";
    }
    return this.to_address.line
      ? `${this.to_address.line}, ${this.toAddressRegion}`
      : `${this.to_address.street_name} ${this.to_address.street_number}, ${this.toAddressRegion}`;
  }

  @computed
  get fromWarehouseAddress() {
    if (!this.from_address) {
      return "";
    }
    return this.from_address.line
      ? `${this.from_address.line}, ${this.fromAddressRegion}`
      : `${this.from_address.street_name} ${this.from_address.street_number}, ${this.fromAddressRegion}`;
  }

  @computed
  get toAddressRegion() {
    if (this.to_address.state === this.to_address.city) {
      return `${startCase(this.to_address.city.toLowerCase())}`;
    }
    return `${startCase(this.to_address.city.toLowerCase())}, ${startCase(
      this.to_address.state.toLowerCase()
    )}`;
  }

  @computed
  get fromAddressRegion() {
    if (this.from_address.state === this.from_address.city) {
      return `${startCase(this.from_address.city.toLowerCase())}`;
    }
    return `${startCase(this.from_address.city.toLowerCase())}, ${startCase(
      this.from_address.state.toLowerCase()
    )}`;
  }

  @action
  save(filters = { fields: "driver,stats", excludes: "items" }) {
    const isPickup = this.isPickup();

    const url = isPickup ? "/pickups" : "/transfers";

    return this._store.save(this, url, true, filters);
  }

  @action
  moveOrders(goodsIn, params) {
    const url = `/goods_ins/${goodsIn.id}/goods_in_items/create_batch`;

    return this._store.appStore.coreAPIClient.sendRequest(url, "POST", {
      items: params,
      excludes: "items",
    });
  }

  @computed
  get pickupDate() {
    return this.confirmed_to || this.requested_to;
  }

  isMyGoodsIn() {
    const currentUser = this._store.appStore.loggedInUser;

    if (currentUser.isMultiAccount()) return true;
    if (currentUser.warehouse == null) return false;

    const warehouse = currentUser.warehouse.peek();
    warehouse.forEach((w) => {
      if (
        w.id === this.from_warehouse_id ||
        w.id === this.from_warehouse.id ||
        w.id === this.to_warehouse_id ||
        w.id === this.to_warehouse.id
      )
        return true;
    });
    return false;
  }

  async paginateData(params) {
    const getGoodsInItems = (page) => {
      const getSearchParams = {
        goods_in_id: this.id,
        page: page,
        ...params,
      };
      return this._store.appStore.coreAPIClient.search(
        "/goods_in_items",
        getSearchParams
      );
    };
    let page = 1;

    let currentPage = await getGoodsInItems(page);
    let results = currentPage.results;
    const totalPages = Math.ceil(
      Number(currentPage.headers["x-total"]) /
        Number(currentPage.headers["x-per-page"])
    );

    while (page < totalPages) {
      page++;

      currentPage = await getGoodsInItems(page);

      results = [...results, ...currentPage.results];
    }
    //return raw data without Order Model.
    //should use getFromData or map each order with Order Model
    return results;
  }
}
